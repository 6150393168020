<template>
  <ftx-dialog
    dialog="UpdateProductQuantityDialog"
    :position="isMobile ? 'bottom' : 'standard'"
    persistent
  >
    <template v-slot:DialogContent>
      <q-card class="updateProductQuantityDialog">
        <div class="dialogHeader text-center q-px-lg q-pt-lg q-pb-sm q-pr-xl">
          <div
            class="text-black q-mt-none"
            :class="isMobile ? 'text-body text-weight-bold' : 'text-h5'"
          >
            Oops! We have found that someone grabs some stock really fast!
            <br />Hurry and place order ASAP to avoid inconvenience
          </div>
          <q-btn
            class="q-mt-md q-mr-md absolute-top-right"
            flat
            round
            dense
            v-close-popup
          >
            <SvgIcon size="14px" icon="close" />
          </q-btn>
        </div>
        <q-card-section class="q-pa-none">
          <q-scroll-area
            class="product-card-container q-pa-sm"
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            :style="
              isMobile
                ? 'height: calc(100vh - 220px);'
                : 'height: calc(80vh - 200px);'
            "
          >
            <q-list v-if="outOfStockProducts.length">
              <q-item
                v-for="(product, index) in outOfStockProducts"
                :key="product.productID + '-' + index"
              >
                <q-item-section>
                  <div class="row q-col-gutter-sm items-center">
                    <div class="col-4 col-md-1">
                      <div class="q-pa-sm">
                        <router-link
                          class="no-underline"
                          :to="{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }"
                          target="_blank"
                        >
                          <q-img
                            spinner-color="primary"
                            :src="productImage(product)"
                            ratio="1"
                          >
                            <template v-slot:loading>
                              <q-spinner
                                color="primary"
                                size="1em"
                                :thickness="2"
                              />
                            </template>
                          </q-img>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-8 col-md-4">
                      <div class="text-subtitle1 text-weight-medium">
                        <router-link
                          class="no-underline text-grey-9"
                          :to="{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }"
                          target="_blank"
                        >
                          {{ product.productName }}
                          <q-tooltip
                            v-if="!isMobile"
                            max-width="180px"
                            content-class="bg-dark"
                          >
                            {{ product.productName }}
                          </q-tooltip>
                        </router-link>
                      </div>
                      <div class="text-subtitle2 text-grey-7"
                        >1 x {{ product.productPrice | currency }}</div
                      >
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="row q-col-gutter-sm items-center">
                        <div class="col-6 text-center">
                          <div class="text-h6 text-red">{{
                            product.orderedQty
                          }}</div>
                          <div class="text-subtitle2 text-uppercase"
                            >You've Ordered</div
                          >
                        </div>
                        <div class="col-6 text-center">
                          <div class="text-h6 text-green">{{
                            product.inStockQty
                          }}</div>
                          <div class="text-subtitle2 text-uppercase"
                            >Available Stock</div
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-2">
                      <q-btn
                        unelevated
                        no-caps
                        rounded
                        outline
                        color="green"
                        label="Update Cart"
                        class="full-width"
                        @click="updateProductQty(product)"
                      />
                    </div>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>

            <div class="row product-items">
              <ProductSkeleton
                class="product-skeleton"
                :size="8"
                v-show="properties.loading"
              />
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-card-actions align="center" class="text-primary q-px-md q-py-lg">
          <!-- <q-btn
            label="Remove All"
            color="red"
            outline
            rounded
            class="q-px-md"
            @click="removeAllOutOfStockProducts"
          /> -->
          <q-btn
            unelevated
            no-caps
            rounded
            :label="`Update Cart Items(${calculateInStockQuantityTotal()})`"
            color="green"
            class="q-px-md"
            padding="12px 24px"
            size="18px"
            @click="updateAllProductQty"
          />
        </q-card-actions>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import ProductSkeleton from 'components/category/ProductSkeleton'
import { mapGetters } from 'vuex'
import { hideShowFullSpinner } from 'src/utils'

export default {
  name: 'UpdateProductQuantityDialog',
  components: {
    ProductSkeleton,
  },
  computed: {
    ...mapGetters('cart', ['outOfStockProducts', 'currentCartNumber']),
    properties() {
      return this.getDialogProperties('UpdateProductQuantityDialog')
    },
    productImage() {
      return (product) =>
        product.productImages.length
          ? product.productImages[0].imageUrl
          : this.$options.filters.defaultProductImage('')
    },
  },
  methods: {
    removeProductFromCart: async function (product) {
      hideShowFullSpinner(true)

      this.$store
        .dispatch('cart/deleteCartItem', {
          orderItemId: product.orderLineItemID,
          removeOutOfStockProduct: true,
        })
        .then(() => {
          this.showSuccess('Item Removed from Cart.')
          if (!this.outOfStockProducts.length)
            this.changeDialogState({
              dialog: 'UpdateProductQuantityDialog',
              val: false,
            })
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    updateProductQty: async function (product) {
      hideShowFullSpinner(true)
      let objRequest = {
        fulfillmentMethodId: product.fulfillmentMethodID,
        products: [
          {
            productId: product.productID,
            qty: product.selectedQty,
          },
        ],
      }
      await this.$store
        .dispatch('cart/addUpdateCartDetail', objRequest)
        .then(() => {
          this.$store.commit('cart/REMOVE_OUT_OF_STOCK_PRODUCT', {
            cartId: this.currentCartNumber,
            orderItemId: product.orderLineItemID,
          })
          if (!this.outOfStockProducts.length)
            this.changeDialogState({
              dialog: 'UpdateProductQuantityDialog',
              val: false,
            })
          this.showSuccess('Cart has been updated.')
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    updateAllProductQty: async function () {
      hideShowFullSpinner(true)
      let objRequest = [],
        fulfillmentProducts = {}
      let outOfStockProducts = [...this.outOfStockProducts]
      fulfillmentProducts = outOfStockProducts.reduce((obj, item) => {
        obj[item['fulfillmentMethodID']] = obj[item['fulfillmentMethodID']]
          ? obj[item['fulfillmentMethodID']]
          : []
        obj[item['fulfillmentMethodID']].push(item)
        return obj
      }, {})
      for (let fulfillmentMethod in fulfillmentProducts) {
        let products = fulfillmentProducts[fulfillmentMethod]
        objRequest.push({
          fulfillmentMethodId: fulfillmentMethod,
          locationID: this.currentLocationId,
          products: products.map((product) => ({
            productId: product.productID,
            orderLineItemID: product.orderLineItemID,
            qty: product.selectedQty,
          })),
        })
      }
      await this.$store
        .dispatch('cart/updateAllOutOfStockProducts', objRequest)
        .then((response) => {
          if (response.data === true) {
            this.changeDialogState({
              dialog: 'UpdateProductQuantityDialog',
              val: false,
            })
            this.showSuccess('Cart has been updated.')
          } else if (response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    removeAllOutOfStockProducts: async function () {
      hideShowFullSpinner(true)
      let objRequest = {
        orderLineItemIDs: this.outOfStockProducts.map(
          (product) => product.orderLineItemID
        ),
      }
      await this.$store
        .dispatch('cart/removeAllOutOfStockProducts', objRequest)
        .then((response) => {
          if (response.data === true) {
            this.changeDialogState({
              dialog: 'UpdateProductQuantityDialog',
              val: false,
            })
            this.showSuccess('Items Removed from Cart.')
          } else if (response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    calculateInStockQuantity(availableQty) {
      let qtyOptions = []
      for (let i = 1; i <= availableQty; i++) {
        qtyOptions.push(i)
      }
      return qtyOptions
    },
    selectedProductQty(selectedQty, product) {
      let payload = {
        ...product,
        selectedQty: selectedQty,
        inStockPrice: parseFloat(
          parseFloat(product.productPrice * selectedQty).toFixed(2)
        ),
      }
      this.$store.commit('cart/SET_OUT_OF_STOCK_PRODUCT_QTY', {
        cartId: this.currentCartNumber,
        product: payload,
      })
    },
    calculateInStockQuantityTotal() {
      var calculatedQTY = 0
      for (let index = 0; index < this.outOfStockProducts.length; index++) {
        const element = this.outOfStockProducts[index]
        calculatedQTY += element.inStockQty
      }
      return calculatedQTY
    },
  },
}
</script>
<style lang="scss">
.hide-icon {
  i {
    display: none;
    @media (max-width: 1023px) {
      display: inline-block;
      margin-right: 0 !important;
    }
    & + span {
      @media (max-width: 1023px) {
        display: none !important;
      }
    }
  }
}
// .cart-checkout-title {
//   @media (max-width: 1023px) {
//     font-size: 12px;
//   }
// }
.product-items.checkout-product-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  .product-items--card {
    width: calc(100% - 10px);
    @media (min-width: 480px) {
      width: calc(50% - 10px);
    }
    @media (min-width: 600px) {
      width: calc(50% - 10px);
    }
    @media (min-width: 1024px) {
      width: calc(33.3% - 10px);
    }
    @media (min-width: 1080px) {
      width: calc(33.3% - 10px);
    }
    @media (min-width: 1360px) {
      width: calc(25% - 10px);
    }
    @media (min-width: 1480px) {
      width: calc(25% - 10px);
    }
  }
  .q-field__control,
  .q-field__marginal,
  .q-field__native {
    min-height: 30px;
    height: 30px;
  }
  .q-field__control {
    min-height: 30px;
  }
  .outstockquantity {
    width: 70px;
    margin: 5px auto;
  }
  .instockqty {
    min-height: 25px;
    line-height: 25px;
  }
}
.no-height {
  min-height: auto !important;
}
body .updateProductQuantityDialog {
  height: 100vh !important;
  width: 100% !important;
  @media (min-width: 767px) {
    height: 80vh !important;
  }
  @media (min-width: 1024px) {
    width: 1000px !important;
    border-radius: 10px !important;
  }
  .product-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    min-width: 100%;
    .product-skeleton {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      width: 100%;
      .product-items--card {
        width: calc(50% - 10px);
        @media (min-width: 600px) {
          width: calc(33.33% - 10px);
        }
        @media (min-width: 1024px) {
          width: calc(25% - 10px);
        }
        @media (min-width: 1440px) {
          width: calc(25% - 10px);
        }
        @media (min-width: 1600px) {
          width: calc(25% - 10px);
        }
      }
    }
  }
  .q-list {
    padding: 0 24px;
    .q-item {
      margin: 20px 0;
      border-radius: 11px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
    @media (max-width: 1023px) {
      padding: 0 8px;
      .q-item {
        padding: 15px;
      }
    }
  }
}
</style>
