var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ftx-dialog',{attrs:{"dialog":"UpdateProductQuantityDialog","position":_vm.isMobile ? 'bottom' : 'standard',"persistent":""},scopedSlots:_vm._u([{key:"DialogContent",fn:function(){return [_c('q-card',{staticClass:"updateProductQuantityDialog"},[_c('div',{staticClass:"dialogHeader text-center q-px-lg q-pt-lg q-pb-sm q-pr-xl"},[_c('div',{staticClass:"text-black q-mt-none",class:_vm.isMobile ? 'text-body text-weight-bold' : 'text-h5'},[_vm._v("\n          Oops! We have found that someone grabs some stock really fast!\n          "),_c('br'),_vm._v("Hurry and place order ASAP to avoid inconvenience\n        ")]),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"q-mt-md q-mr-md absolute-top-right",attrs:{"flat":"","round":"","dense":""}},[_c('SvgIcon',{attrs:{"size":"14px","icon":"close"}})],1)],1),_c('q-card-section',{staticClass:"q-pa-none"},[_c('q-scroll-area',{staticClass:"product-card-container q-pa-sm",style:(_vm.isMobile
              ? 'height: calc(100vh - 220px);'
              : 'height: calc(80vh - 200px);'),attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[(_vm.outOfStockProducts.length)?_c('q-list',_vm._l((_vm.outOfStockProducts),function(product,index){return _c('q-item',{key:product.productID + '-' + index},[_c('q-item-section',[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-4 col-md-1"},[_c('div',{staticClass:"q-pa-sm"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: product.seoName },
                        },"target":"_blank"}},[_c('q-img',{attrs:{"spinner-color":"primary","src":_vm.productImage(product),"ratio":"1"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner',{attrs:{"color":"primary","size":"1em","thickness":2}})]},proxy:true}],null,true)})],1)],1)]),_c('div',{staticClass:"col-8 col-md-4"},[_c('div',{staticClass:"text-subtitle1 text-weight-medium"},[_c('router-link',{staticClass:"no-underline text-grey-9",attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: product.seoName },
                        },"target":"_blank"}},[_vm._v("\n                        "+_vm._s(product.productName)+"\n                        "),(!_vm.isMobile)?_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                          "+_vm._s(product.productName)+"\n                        ")]):_vm._e()],1)],1),_c('div',{staticClass:"text-subtitle2 text-grey-7"},[_vm._v("1 x "+_vm._s(_vm._f("currency")(product.productPrice)))])]),_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-6 text-center"},[_c('div',{staticClass:"text-h6 text-red"},[_vm._v(_vm._s(product.orderedQty))]),_c('div',{staticClass:"text-subtitle2 text-uppercase"},[_vm._v("You've Ordered")])]),_c('div',{staticClass:"col-6 text-center"},[_c('div',{staticClass:"text-h6 text-green"},[_vm._v(_vm._s(product.inStockQty))]),_c('div',{staticClass:"text-subtitle2 text-uppercase"},[_vm._v("Available Stock")])])])]),_c('div',{staticClass:"col-12 col-md-2"},[_c('q-btn',{staticClass:"full-width",attrs:{"unelevated":"","no-caps":"","rounded":"","outline":"","color":"green","label":"Update Cart"},on:{"click":function($event){return _vm.updateProductQty(product)}}})],1)])])],1)}),1):_vm._e(),_c('div',{staticClass:"row product-items"},[_c('ProductSkeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.properties.loading),expression:"properties.loading"}],staticClass:"product-skeleton",attrs:{"size":8}})],1)],1)],1),_c('q-card-actions',{staticClass:"text-primary q-px-md q-py-lg",attrs:{"align":"center"}},[_c('q-btn',{staticClass:"q-px-md",attrs:{"unelevated":"","no-caps":"","rounded":"","label":`Update Cart Items(${_vm.calculateInStockQuantityTotal()})`,"color":"green","padding":"12px 24px","size":"18px"},on:{"click":_vm.updateAllProductQty}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }